.menu {
	display: flex;
  height: 70px;
  font-family: var(--title-font-family);
   & > * {
    font-size: 1.5rem;
    color: var(--text-color-main);
    text-decoration: none;
  }
}

.menu-navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
  padding: 0.25rem 1rem;
}

.menu-title-link {
	@include media-breakpoint-down(sm) {
		font-size: 1rem;
		max-width: 250px;
	}
}

/*
 * Navigation list
 */

.nav-wrapper {
	display: flex;
	align-items: center;
}

.menu-list {
	display: flex;
	padding: 0;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.menu-list-item {
	margin: 0 1rem;
	list-style: none;
}

/*
 * Navigation menu button
 */

.menu-button {
	display: none;
	font-size: 1rem;
	padding: 0;
	border: none;
	background-color: transparent;
	color: var(--text-color-main);
	font-family: var(--title-font-family);
	font-weight: 900;
	cursor: pointer;

	&:focus {
		outline: 0;
	}

	&:active {
		color: var(--text-color-main);
	}

	@include media-breakpoint-down(sm) {
		display: block;
	}
}