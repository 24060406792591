.footer-menu {
	display: flex;
	justify-content: space-between;
	padding: 1rem;
	font-size: 12px;

	&-link {
		text-decoration: underline;
	}

	@include media-breakpoint-down(xs) {
		flex-direction: column;
		align-items: center;
		
		& span:first-of-type {
			margin-bottom: 0.25rem;
		}
	}
}