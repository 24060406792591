$tag-space: 0.15rem;

.tags-list {
	display: flex;
  flex-wrap: wrap;
  margin: 0;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.tag {
	margin: $tag-space 0;
	padding: $tag-space 0;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

.tag-link {
	margin: 0 $tag-space;
	padding: $tag-space $tag-space * 3;
	font-size: 0.85rem;
	border: 1px solid black;
	border-radius: 20px;
	transition: background-color 250ms var(--cubic-bezier),
							color 250ms var(--cubic-bezier);

	&:hover, 
	&.is-active {
		background-color: black;
		color: white;
	}
}