.home {
	@include make-container();
	display: flex;
	flex-direction: column;
}


.project-preview-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 50px 50px;
  justify-items: center;
  justify-content: space-evenly;
  align-items: center;
  margin: 100px 0;

  @media only screen and (max-width: "1100px") {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: "992px") {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: "768px") {
    grid-template-columns: 1fr;
    margin: 50px 0;
  }
}

.project-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
}

.project-preview-title {
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
  max-width: 30ch;
}