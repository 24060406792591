.about {
	@include make-container();
	@include make-container-max-widths();
	display: flex;
	margin-top: 50px;

	@include media-breakpoint-down(md) {
		flex-direction: column;
		margin-top: 25px;
	}
}

.about-profile-pic,
.about-description {
	margin-left: 0.5rem;
	margin-right: 0.5rem;

	@include media-breakpoint-down(md) {
		margin-left: 0rem;
		margin-right: 0rem;
	}
}

.about-profile-pic {
  display: flex;
  justify-content: center;
	align-items: center;
	width: 50%;
	
	@include media-breakpoint-down(md) {
		display: flex;
		justify-content: center;
    width: 100%;
    margin-bottom: 50px;
		
		& img {
			max-height: 300px;
		}
	}
}

.about-description {
	width: 50%;
	font-size: 1.5rem;
	font-weight: 400;

	@include media-breakpoint-down(md) {
    width: 100%;
    font-size: 1.15rem;
  }
  
  & p:first-of-type {
    margin-top: 0;
  }

	& h1, h2 {
		font-size: 2rem;
    margin: 0 0 0.5rem 0;
    font-family: var(--title-font-family);

    @include media-breakpoint-down(md) {
      font-size: 1.625rem;
    }
  }
  
  & ul {
    margin-top: 0;
  }

	& svg {
		width: 25px;
		height: 25px;

		& rect {
			fill: black;
		}
	}
}

.egg-about img:hover {
  cursor: progress;
}