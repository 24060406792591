.flickity-button {
  display: block;

  &:hover {
    background-color: transparent;
  }
}

.flickity-page-dots {
  display: none;
}

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 50%;
  height: 100%;
  border: none;
  border-radius: 0;
  background: none;
  cursor: none;
  outline: 0;

  & svg {
    display: none;
  }
}

.flickity-prev-next-button.previous {
  left: 0;

  &:hover {
    cursor: w-resize;
  }
}

.flickity-prev-next-button.next {
  right: 0;

  &:hover {
    cursor: e-resize;
  }
}

.flickity-fullscreen-button {
	display: block;
	right: 10px;
	top: 10px;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	background-color: white;

	&-view {
		z-index: 9999;
	}
}