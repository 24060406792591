.menu-mobile {
	position: fixed;
	top: 70px;
	left: 0px;
	right: 0px;
	width: 100%;
	height: 100%;
	padding-bottom: 100px;
	background-color: var(--background-color-primary);
	overflow-y: auto;
	opacity: 0;
	visibility: hidden;
	transition: all 250ms var(--cubic-bezier);
	z-index: 1000;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	// Do not display the menu on large screen
	@include media-breakpoint-up(md) {
		display: none;
	}

	&-list {
		margin-top: 25px;
		margin-bottom: 25px;
    margin-left: 1rem;
    margin-right: 1rem;
    font-family: var(--body-font-family);
		font-size: 1rem;
		font-weight: 100;
		color: var(--text-color-main);
	}

	&-list-item {
		margin: 1rem 0;
	}
}

.menu-break {
  display: none;

  @include media-breakpoint-down(xs) {
    display: block;
  }
}