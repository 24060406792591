.project {
	@include make-container();
  margin: 25px 0;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  &-year {
		font-size: 1.25rem;
		letter-spacing: 3px;
	}

	&-title {
		margin-top: 0.25rem;
		margin-bottom: 1rem;
		font-size: 2rem;
    font-weight: 900;
    font-family: var(--title-font-family);

		@include media-breakpoint-down(md) {
			font-size: 1.625rem;
		}
	}

	&-description {
		font-size: 1.25rem;
    line-height: 1.25;
    max-width: 70ch;

    & a {
      position: relative;
      font-weight: 700;
      border-bottom: 1px solid var(--text-color-main);
      line-height: 1.5;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.15rem;
      line-height: 1.15;
		}
	}

	&-meta {
		margin-top: 0.25rem;
		font-size: 0.85rem;

		&-type {
			display: block;
			font-weight: 700;
			margin: 0.25rem 0;
		}

		&-medium {
			display: block;
			margin: 0.25rem 0;
		}

		&-collaborators {
			margin: 0.25rem 0;
		}
	}

	&-container {
		display: flex;
		flex-direction: row;

		@include media-breakpoint-down(md) {
      flex-direction: column;
		}
	}

	&-infos {
		position: sticky;
		top: 25px;
		width: 35%;
    margin-right: 25px;

    @include media-breakpoint-down(lg) {
      width: 45%;
    }

		@include media-breakpoint-down(md) {
			position: relative;
			width: 100%;
			min-height: unset;
			margin-right: unset;
		}
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .project-button {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &--fullscreen {
      & svg {
        width: 20px;
        height: 20px;
      }
    }

    &--prev,
    &--next {
      font-size: 1.5rem;
    }

    &--prev {
      margin-right: 0.5rem;
    }

    &--next {
      margin-left: 0.5rem;
    }
  }

	.project-carousel-wrapper {
		position: sticky;
		top: 25px;
		width: 65%;
    height: 75vh;
    min-height: 500px;

    @include media-breakpoint-down(lg) {
      width: 55%;
    }

		@include media-breakpoint-down(md) {
			position: relative;
			width: 100%;
      height: 100%;
		}
	}

	.project-carousel {
		width: 100%;
		height: 100%;
		background: transparent;

		&:after {
			content: 'flickity';
			display: none;
		}

		@include media-breakpoint-down(md) {
			/* Disable Flickity for small devices */
			&:after {
				content: '';
			}
		}
  }

  .project-carousel-counter {
    margin-bottom: 1rem;
    font-size: 0.75rem;
    text-align: center;

    @include media-breakpoint-down(md) {
			display: none;
		}
  }

	.project-carousel-cell {
		width: 100%;
		height: 100%;
    margin: 0;

		@include media-breakpoint-down(md) {
			margin-bottom: 1rem;
		}
  }

  .project-carousel-cell-image {
    max-height: calc(100% - 18px - 0.5rem);
    width: 100%;
    outline: 0;
    object-fit: contain;

		@include media-breakpoint-down(md) {
      width: 100%;
      height: 100%;
		}
  }

  .project-carousel-cell-video {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; // Keep 16/9 ratio

    & video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
	}

	.project-carousel-cell-image-description {
	  display: flex;
		justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    font-size: 0.75rem;

    @include media-breakpoint-down(md) {
			margin-top: 0.25rem;
		}
	}
}

.project-next-prev {
  display: flex;
  justify-content: space-between;
  margin: 100px 0 25px 0;

  @include media-breakpoint-down(md) {
    margin-top: 50px;
  }
}

.project-next-prev-link {
  font-size: 0.85rem;
  font-weight: 700;
  border-bottom: none;

  @include media-breakpoint-down(xs) {
    font-size: 0.75rem;
  }
}
