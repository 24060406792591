// Global variables
:root {
	/* Colors */
	--background-color-primary: #E6D8B6;
	--background-color-secondary: #FFFFFF;
	--text-color-main: #111111;
	/* Fonts */
  --body-font-family: 'Work Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --title-font-family: 'Right Grotesk', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --cubic-bezier: cubic-bezier(0.645, 0.045, 0.355, 1);
  --shakeValue: 1deg;
}

// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
