@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@keyframes shake {
  0% {
    transform: translateY(-2px);
  }

  10% {
    transform: rotateZ(var(--shakeValue)) translateY(-2px);
  }

  20% {
    transform: rotateZ(calc(var(--shakeValue) * -1)) translateY(-2px);
  }

  30% {
    transform: rotateZ(var(--shakeValue)) translateY(-2px);
  }

  40% {
    transform: rotateZ(calc(var(--shakeValue) * -1)) translateY(-2px);
  }

  50% {
    transform: rotateZ(var(--shakeValue)) translateY(-2px);
  }

  60% {
    transform: rotateZ(calc(var(--shakeValue) * -1)) translateY(-2px);
  }

  70% {
    transform: rotateZ(var(--shakeValue)) translateY(-2px);
  }

  80% {
    transform: rotateZ(0deg) translateY(-2px);
  }

  100% {
    transform: rotateZ(0deg) translateY(-2px);
  }
}