.languages-list {
	display: flex;
	margin: 0;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.languages-link {
  & a {
    font-size: 1rem;
  }

	& a:hover {
		border-bottom: 1px solid black;
	}

	&:first-of-type {
		margin-right: 0.25rem;
	}

	&:last-of-type {
		margin-left: 0.25rem;
	}
}