@mixin make-container {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto;
}

@mixin make-container-max-widths {
	@include media-breakpoint-down(xl) {
		max-width: map-get($container-max-widths, xl);
	}

	@include media-breakpoint-down(lg) {
		max-width: map-get($container-max-widths, lg);
	}

	@include media-breakpoint-down(md) {
		max-width: map-get($container-max-widths, md);
	}

	@include media-breakpoint-down(sm) {
		max-width: map-get($container-max-widths, sm);
	}

	@include media-breakpoint-down(xs) {
		max-width: 100%;
	}
}