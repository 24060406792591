html {
  box-sizing: border-box;
  line-height: 1.15;
  font-family: var(--body-font-family);
	text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  background-color: var(--background-color-primary);
  transition: background-color 250ms var(--cubic-bezier);
}

main {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;

  &:visited {
    color: inherit;
  }
}

a.external-link {
  &::after {
    content: '↗';
    margin-left: 8px;
  }
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  border-style: none;
}

h1, h2, h3 {
  font-weight: 900;
}