$body_font_path: '../fonts/WorkSans/';
$title_font_path: '../fonts/RightGrotesk/';

@font-face {
	font-family: 'Work Sans';
	font-display: swap;
	src: url($body_font_path + 'WorkSans-Regular.woff2') format('woff2'),
			 url($body_font_path + 'WorkSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Right Grotesk';
	font-display: swap;
	src: url($title_font_path + 'RightGrotesk-SpatialBold.woff2') format('woff2'),
			 url($title_font_path + 'RightGrotesk-SpatialBold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}