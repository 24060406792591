$egg_width: 225px;
$egg_height: 300px;
$egg_width_large: 350px;
$egg_height_large: 467px;

.egg {
  display: flex;
  justify-content: center;
  max-height: 300px;
  margin: 25px 0;
  transform: translateY(0);
  transition: transform 250ms var(--cubic-bezier);

  &-large {
    @include media-breakpoint-up(lg) {
      max-height: $egg_height_large;
    }
  }

  &:hover {
    transform: translateY(-2px);
  }
}

.egg-image {
  width: $egg_width;
  height: $egg_height;
  object-fit: cover;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000px;

  &-large {
    @include media-breakpoint-up(lg) {
      width: $egg_width_large;
      height: $egg_height_large;
    }
  }

  &:hover {
    cursor: pointer;
    animation-name: shake;
    animation-duration: 1.5s;
    animation-timing-function: var(--cubic-bezier);
    animation-iteration-count: infinite;
    transform: translateY(-10px);

    & + .egg-shadow {
      opacity: 1;
      transform: translateX(-50%) translateY(262px) scale(1.025, 1);

      &-large {
        @include media-breakpoint-up(lg) {
          transform: translateX(-50%) translateY(405px) scale(1.025, 1);
        }
      }
    }
  }
}

.egg-shadow {
  position: absolute;
  width: 275px;
  height: 50px;
  z-index: -1;
  top: 0;
  left: 50%;
  opacity: 0.9;
  transform: translateX(-50%) translateY(260px) scale(1, 1);
  border-radius: 50%;
  background: radial-gradient(circle, rgb(39, 29, 2) 0%, rgba(255,255,255,0) 100%);
  filter: blur(15px);
  transition: opacity 150ms cubic-bezier(0.645, 0.045, 0.355, 1),
              transform 300ms cubic-bezier(0.55, 0, 0.1, 1);

  &-large {
    @include media-breakpoint-up(lg) {
      width: 400px;
      height: 75px;
      transform: translateX(-50%) translateY(400px) scale(1, 1);
    }
  }
}
